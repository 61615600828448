<template>
  <div>

    <div v-show="mostrar_financeiro" class="grid">
      <div class="col-12 lg:col-12 xl:col-12">
        <div class="card mb-0">
          <label for="id_fornecedor">Filtrar por Fornecedor/Mês/Ano</label><br>
          <Dropdown filter id="id_fornecedor" required="true" v-model="id_fornecedor" :options="fornecedores"
            optionLabel="label" optionValue="value" placeholder="Selecione um Fornecedor">
          </Dropdown>

          <Dropdown filter id="mes" required="true" v-model="mes" :options="meses" class="ml-3" optionLabel="label"
            optionValue="value" placeholder="Selecione um Mês">
          </Dropdown>
          <Dropdown filter id="ano" required="true" v-model="ano" :options="anos" class="ml-3" optionLabel="label"
            optionValue="value" placeholder="Selecione um Ano">
          </Dropdown>
          <Button class="ml-3" :loading="loading" @click="listarInicial()">Filtrar</Button>
        </div>
      </div>
    </div>

    <div v-show="mostrar_blocos" class="grid">
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Planilhas<br> Importadas</span>
              <div class="text-900 font-medium text-xl">{{ planilhas_importadas }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-cloud-upload text-blue-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Planilhas <br>para Validar</span>
              <div class="text-900 font-medium text-xl">{{ planilhas_validar }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-orange-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-info-circle text-orange-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Planilhas <br>Validadas</span>
              <div class="text-900 font-medium text-xl">{{ planilhas_validadas }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-cyan-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-check-circle text-cyan-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"> </span>
        </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3">Planilhas <br>Reprovadas</span>
              <div class="text-900 font-medium text-xl">{{ planilhas_reprovadas }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-red-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-exclamation-triangle text-red-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3"> Pagamentos <br>Aprovados</span>
              <div class="text-900 font-medium text-xl">{{ planilhas_pagamento_aprovado }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-green-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-check text-green-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3"> Pagamentos <br>Rejeitados</span>
              <div class="text-900 font-medium text-xl">{{ planilhas_pagamento_rejeitado }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-red-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-exclamation-triangle text-red-500 text-xl"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!--Blocos financeiros-->
    <div class="grid" v-show="mostrar_financeiro">
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between ">
            <div>
              <span class="block text-500 font-medium mb-3">Total<br>  End ID</span>
              <div class="text-900 font-medium text-xl">{{ total_end_id }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-cloud-upload text-blue-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between ">
            <div>
              <span class="block text-500 font-medium mb-3">End ID<br>  Validado</span>
              <div class="text-900 font-medium text-xl">{{ end_id_validado }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-green-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-check text-green-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between ">
            <div>
              <span class="block text-500 font-medium mb-3">End ID<br> Contestado</span>
              <div class="text-900 font-medium text-xl">{{ end_id_contestado }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-orange-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-exclamation-triangle text-orange-500 text-xl"></i>
            </div>
          </div>
          <span class="text-orange-500 font-medium"> </span>
        </div>
      </div>


      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between ">
            <div>
              <span class="block text-500 font-medium mb-3">Valor<br>  Solicitado</span>
              <div class="text-900 font-medium text-xl">{{ valor_total }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-dollar text-blue-500 text-xl"></i>
            </div>
          </div>
          <span class="text-blue-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between ">
            <div>
              <span class="block text-500 font-medium mb-3">Valor<br>  Contestado</span>
              <div class="text-900 font-medium text-xl">{{ valor_contestado }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-red-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-dollar text-red-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between ">
            <div>
              <span class="block text-500 font-medium mb-3">Valor<br>  Validado</span>
              <div class="text-900 font-medium text-xl">{{ valor_validado }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-green-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-dollar text-green-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between ">
            <div>
              <span class="block text-500 font-medium mb-3">Valor <br> Aprovado (Financeiro)</span>
              <div class="text-900 font-medium text-xl">{{ valor_aprovado_mes }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-green-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-dollar text-green-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
    </div>

    <hr v-show="mostrar_blocos" />
    <div class="card" v-show="mostrar_planilha">
      <div class="grid">
        <div class="col-12">
          <div class="field m-0">
            <label for="id_fornecedor">Filtrar por Fornecedor/Mês/Ano</label><br>
            <Dropdown filter id="id_fornecedor" required="true" v-model="id_fornecedor" :options="fornecedores"
              optionLabel="label" optionValue="value" placeholder="Selecione um Fornecedor">
            </Dropdown>

            <Dropdown filter id="mes" required="true" v-model="mes" :options="meses" class="ml-3" optionLabel="label"
              optionValue="value" placeholder="Selecione um Mês">
            </Dropdown>
            <Dropdown filter id="ano" required="true" v-model="ano" :options="anos" class="ml-3" optionLabel="label"
              optionValue="value" placeholder="Selecione um Ano">
            </Dropdown>
            <Button class="ml-3" :loading="loading" @click="listarInicial()">Filtrar</Button>
          </div>

        </div>
      </div>

    </div>
    <div class="card" v-show="mostrar_planilha">
      <h5>Planilhas Importadas por Fornecedores - Aguardando Aprovação</h5>
      <DataTable stripedRows v-model:filters="filters" :loading="loading" :value="planilhas" :rows="5" :paginator="true"
        responsiveLayout="scroll" scrollable scrollHeight="400px"
        :globalFilterFields="['razao_social', 'data_upload', 'status', 'mes_referencia']">
        <template #empty> Nenhum dado localizado. </template>
        <template #header>
          <div class="flex justify-content-end">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Buscar" />
            </span>
          </div>
        </template>
        <Column style="min-width:120px" frozen field="data_upload" header="Data Upload" :sortable="true"></Column>

        <Column style="min-width:420px" frozen field="razao_social" header="Fornecedor" :sortable="true">

        </Column>
        <Column style="min-width:200px" field="mes_referencia" header="Mês Referência" :sortable="true">
        </Column>
        <Column style="min-width:150px" field="id" header="Número do Lote" :sortable="true">
        </Column>
        <Column style="min-width:200px" field="status" header="Status do Processo" :sortable="true">
        </Column>
        <Column style="min-width:200px" field="responsavel" header="Responsável" :sortable="true">
        </Column>


        <Column style="min-width:300px" field="qtd_endid_enviado" header="Quantidade End Id Enviado" :sortable="true">
        </Column>

        <Column style="min-width:300px" field="qtd_endid_aprovado" header="Quantidade End Id Aprovado" :sortable="true">
        </Column>

        <Column style="min-width:300px" field="valor_endid_aprovado" header="Valor End Id Aprovado" :sortable="true">
        </Column>

        <Column style="min-width:300px" field="qtd_endid_reprovado" header="Quantidade End Id Reprovados"
          :sortable="true">
        </Column>

        <Column style="min-width:300px" field="valor_endid_reprovado" header="Valor End Id Reprovado" :sortable="true">
        </Column>

        <Column style="min-width:40px" alignFrozen="right" frozen>
          <template #body="slotProps">
            <Button icon="pi pi-search" @click="mostrarPlanilha(slotProps.data, 1)" type="button"
              class="p-button-text"></Button>
          </template>
        </Column>
      </DataTable>
    </div>


    <div class="col-12 xl:col-12" v-show="mostrar_blocos_fornecedor">
      <div class="card">
        <h5>Planilhas Importadas</h5>
        <DataTable filter :value="planilhas" :rows="5" :paginator="true" responsiveLayout="scroll">
          <Column field="data_upload" header="Data Upload" :sortable="true"></Column>

          <Column field="razao_social" header="Fornecedor" :sortable="true">

          </Column>
          <Column field="mes_referencia" header="Mês Referência" :sortable="true">
          </Column>
          <Column field="id" header="Número do Lote" :sortable="true">
          </Column>
          <Column field="status" header="Status do Processo" :sortable="true">
          </Column>
          <Column field="responsavel" header="Responsável" :sortable="true">
          </Column>
          <Column style="width: 15%">
            <template #header> Dados Importados </template>

            <template #body="slotProps">
              <Button icon="pi pi-search" @click="mostrarPlanilha2(slotProps.data, 2)" type="button"
                class="p-button-text"></Button>


            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>

  <!-- Modal fornecedor -->
  <Dialog v-model:visible="mostrarPlanilhaModal" :style="{ width: '80%' }" header="Planilha Fornecedor" :modal="true">
    <div class="grid">
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between ">
            <div>
              <span class="block text-500 font-medium mb-3">Total End ID</span>
              <div class="text-900 font-medium text-xl">{{ total_end_id }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-cloud-upload text-blue-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between ">
            <div>
              <span class="block text-500 font-medium mb-3">End ID Validado</span>
              <div class="text-900 font-medium text-xl">{{ end_id_validado }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-green-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-check text-green-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between ">
            <div>
              <span class="block text-500 font-medium mb-3">End ID Contestado</span>
              <div class="text-900 font-medium text-xl">{{ end_id_contestado }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-orange-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-exclamation-triangle text-orange-500 text-xl"></i>
            </div>
          </div>
          <span class="text-orange-500 font-medium"> </span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between ">
            <div>
              <span class="block text-500 font-medium mb-3">Valor Solicitado</span>
              <div class="text-900 font-medium text-xl">{{ valor_total }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-dollar text-blue-500 text-xl"></i>
            </div>
          </div>
          <span class="text-blue-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between ">
            <div>
              <span class="block text-500 font-medium mb-3">Valor Contestado</span>
              <div class="text-900 font-medium text-xl">{{ valor_contestado }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-red-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-dollar text-red-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
      <div class="col-12 lg:col-6 xl:col-2">
        <div class="card mb-0">
          <div class="flex justify-content-between ">
            <div>
              <span class="block text-500 font-medium mb-3">Valor Validado</span>
              <div class="text-900 font-medium text-xl">{{ valor_validado }}</div>
            </div>
            <div class="
              flex
              align-items-center
              justify-content-center
              bg-green-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
              <i class="pi pi-dollar text-green-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium"></span>
        </div>
      </div>
    </div>
    <hr />

    <div class="col-12 lg:col-12 xl:col-12" style="text-align:right;">
      <Button label="Exportar" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
    </div>

    <DataTable tableStyle="min-width: 50rem" ref="dt" exportFilename="relatorio[PLANILHA]" csvSeparator=";"
      editMode="cell" tableClass="editable-cells-table" :value="relatorios" dataKey="id" :paginator="true" :rows="50"
      :filters="filters" :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25, 50, 100, 200]" stripedRows scrollable scrollHeight="600px"
      currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)" responsiveLayout="scroll">
      <template #header>
        <div class="
                  flex flex-column
                  md:flex-row md:justify-content-between md:align-items-center
                ">
          <h5 class="m-0">Planilha aqui</h5>



        </div>
      </template>

      <Column frozen field="site_id_tim" header="Site ID TIM" footer="Site ID TIM" style="min-width: 200px">
      </Column>

      <Column frozen field="endereco_id_tim" header="END ID TIM" footer="END ID TIM" style="min-width: 200px">
      </Column>

      <Column field="regional_tim" header="Regional TIM" footer="Regional TIM" style="min-width: 200px">
      </Column>

      <Column field="uf" header="UF" footer="UF" style="min-width: 100px"></Column>

      <Column field="detentora" header="Detentora" footer="Detentora" style="min-width: 200px"></Column>

      <Column field="site_id_detentora" header="Site ID Detentora" footer="Site ID Detentora" style="min-width: 200px">
      </Column>

      <Column field="data_rfi" header="Data RFI" footer="Data RFI" style="min-width: 200px"></Column>

      <Column field="tipo_de_energia" header="Tipo de Energia" footer="Tipo de Energia" style="min-width: 200px"></Column>

      <Column field="consumo_kWh" header="Consumo (KWh)" footer="Consumo (KWh)" style="min-width: 200px">
      </Column>

      <Column field="e_viavel_instalacao_de_medidor_tim" header="É viável Instalação de Medidor TIM?"
        footer="É viável Instalação de Medidor TIM?" style="min-width: 300px"></Column>

      <Column field="justificativa" header="Justificativa" footer="Justificativa" style="min-width: 600px">
      </Column>

      <Column field="mes_de_referencia_do_formulario" header="Mês de Referência do Formulário"
        footer="Mês de Referência do Formulário" style="min-width: 300px"></Column>

      <Column field="data_de_envio_do_formulario" header="Data de envio do formulário"
        footer="Data de envio do formulário" style="min-width: 300px"></Column>

      <Column field="mes_de_Referencia_da_fatura" header="Mês de Referência da Fatura"
        footer="Mês de Referência da Fatura" style="min-width: 300px"></Column>

      <Column field="valor_total_fatura" header="Valor Total Fatura (R$)" footer="Valor Total Fatura (R$)"
        style="min-width: 200px"></Column>

      <Column field="concatenado" header="Concatenado (END_ID + Dententora)" footer="Concatenado (END_ID + Dententora)"
        style="min-width: 300px"></Column>

      <Column field="status_base_cadastral" header="Status Base Cadastral" footer="Status Base Cadastral"
        style="min-width: 200px"></Column>

      <Column field="status_sge" header="Status SGE" footer="Status SGE" style="min-width: 200px">
      </Column>

      <Column field="data_entrante_sge" header="Data Entrante SGE" footer="Data Entrante SGE" style="min-width: 200px">
      </Column>

      <Column field="status_titularidade" header="Status Titularidade" footer="Status Titularidade"
        style="min-width: 200px"></Column>

      <Column field="status_site_opm" header="Status Site OPM" footer="Status Site OPM" style="min-width: 200px"></Column>

      <Column field="etapa_atual_netflow" header="Etapa Atual Netflow" footer="Etapa Atual Netflow"
        style="min-width: 200px"></Column>

      <Column field="classificacao_do_reembolso" header="Classificação do Reembolso" footer="Classificação do Reembolso"
        style="min-width: 300px"></Column>

      <Column field="motivo_da_contestacao" header="Motivo da Contestação" footer="Motivo da Contestação"
        style="min-width: 300px">
        <template #body="{ data, field }">
          {{ data[field] }}
        </template>
        <template #editor="{ data, field }">
          <Dropdown id="id_status" v-model="data[field]" :options="motivos_contestacao" optionLabel="label"
            optionValue="value" placeholder="Selecionar o Motivo" class="mr-2 ml-5 mt-2">
          </Dropdown>
        </template>
      </Column>



      <Column field="valor_contestado" header="Valor Contestado (R$)" style="min-width: 200px">
        <template #body="{ data, field }">
          {{ data[field] }}
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>

      <Column field="valor_validado" header="Valor Validado (R$)" footer="Valor Validado (R$)" style="min-width: 200px">
        <template #body="{ data, field }">
          {{ data[field] }}
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
      </Column>

      <Column field="status" header="Status" footer="Status" style="min-width: 200px">
        <template #body="{ data, field }">
          {{ data[field] }}
        </template>
        <template #editor="{ data, field }">


          <Dropdown id="id_status" v-model="data[field]" :options="status_contestado" optionLabel="label"
            optionValue="value" placeholder="Selecionar o Status" class="mr-2 ml-5 mt-2">
          </Dropdown>
        </template>
      </Column>

      <Column field="data_validacao" header="Data da validação" footer="Data da validação" style="min-width: 200px">

      </Column>

      <Column v-if="mostrar_link" field="link" header="Link" footer="Link" style="min-width: 600px"></Column>
    </DataTable>


    <template #footer v-if="mostrarAcao">
      <Dropdown id="id_status" v-model="id_status" :options="status" optionLabel="label" optionValue="value"
        placeholder="Selecionar o Status" class="mr-2 ml-5 mt-2">
      </Dropdown>
      <Button label="Salvar" icon="pi pi-check" class="p-button-success p-button-sm" @click="salvarStatus" />
    </template>
  </Dialog>
</template>

<script>
import EventBus from "@/AppEventBus";
import ProductService from "../service/ProductService";
import { FilterMatchMode } from 'primevue/api';
export default {
  data() {
    return {
      planilha_fornecedor: [],
      mostrarAcao: true,
      status: [
        { label: "Aguardando análise", value: 0 },
        { label: "Validada", value: 1 },
        { label: "Rejeitada", value: 2 },


      ],
      mostrar_financeiro: false,

      anos: [
        { label: "", value: "" },
        { label: "2021", value: "2021" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" }],
      meses: [
        { label: "", value: "" },
        { label: "Janeiro", value: "01" },
        { label: "Fevereiro", value: "02" },
        { label: "Março", value: "03" },
        { label: "Abril", value: "04" },
        { label: "Maio", value: "05" },
        { label: "Junho", value: "06" },
        { label: "Julho", value: "07" },
        { label: "Agosto", value: "08" },
        { label: "Setembro", value: "09" },
        { label: "Outubro", value: "10" },
        { label: "Novembro", value: "11" },
        { label: "Dezembro", value: "12" },
      ],
      id_fornecedor: 0,
      mes: 0,
      ano: 0,
      fornecedores: [],
      financeiro: true,
      mostrar_planilha: true,

      total_end_id: 0,
      end_id_validado: 0,
      end_id_contestado: 0,
      valor_validado: 0,
      valor_contestado: 0,
      valor_total: 0,

      cabecalho: [
        { header: "Site ID TIM", field: 'site_id_tim' },
        { header: "END ID TIM", field: 'endereco_id_tim' },
        { header: "Regional TIM", field: 'regional_tim' },
        { header: "UF", field: 'uf' },
        { header: "Detentora ", field: 'detentora' },
        { header: "Site ID Detentora", field: 'site_id_detentora' },
        { header: "Data RFI", field: 'data_rfi' },
        { header: "Tipo de Energia", field: 'tipo_energia' },
        { header: "Consumo (KWh)", field: 'consumo' },
        { header: "É viável Instalação de Medidor TIM?", field: 'viavel_instalacao_medidor_tim' },
        { header: "Justificativa", field: 'justificativa' },
        { header: "Mês de Referência do Formulário", field: 'mes_referencia_formulario' },
        { header: "Data de envio do formulário", field: 'data_envio_formulario' },
        { header: "Mês de Referência da Fatura", field: 'mes_referencia_fatura' },
        { header: "Valor Total Fatura (R$) ", field: 'valor_total_fatura' },
        { header: "Concatenado (END_ID + Dententora)", field: 'concatenado' },
        { header: "Link", field: 'link' }

      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      planilhas: [],
      mostrarPlanilhaModal: false,
      unidades: 0,
      faturas: 0,
      loading: false,
      id_status: 0,
      id_planilha: 0,
      planilhas_importadas: 0,
      planilhas_validar: 0,
      planilhas_validadas: 0,
      planilhas_pagamento_aprovado: 0,
      planilhas_pagamento_rejeitado: 0,
      mostrar_blocos: true,
      mostrar_blocos_fornecedor: false,
      lineData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Revenue",
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: "#2f4860",
            borderColor: "#2f4860",
            tension: 0.4,
          },
          {
            label: "Sales",
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: "#00bb7e",
            borderColor: "#00bb7e",
            tension: 0.4,
          },
        ],
      },
      items: [
        { label: "Add New", icon: "pi pi-fw pi-plus" },
        { label: "Remove", icon: "pi pi-fw pi-minus" },
      ],
      lineOptions: null,
    };
  },
  productService: null,
  themeChangeListener: null,
  mounted() {
    this.listarFornecedor();
    this.montarHome();
    this.listarInicial();
    this.productService
      .getProductsSmall()
      .then((data) => (this.products = data));

    this.themeChangeListener = (event) => {
      if (event.dark) this.applyDarkTheme();
      else this.applyLightTheme();
    };
    EventBus.on("change-theme", this.themeChangeListener);

    if (this.isDarkTheme()) {
      this.applyDarkTheme();
    } else {
      this.applyLightTheme();
    }
  },
  beforeUnmount() {
    EventBus.off("change-theme", this.themeChangeListener);
  },
  created() {
    this.productService = new ProductService();
  },
  methods: {

    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    listarRelatorio(id) {
      let dados = {
        id: id
      }
      this.axios
        .post("/relatorios/fornecedor", dados)
        .then((response) => {
          console.log(response);
          this.relatorios = response.data.dados;

          this.total_end_id = response.data.quadros.qtd_endid_enviado;
          this.end_id_validado = response.data.quadros.qtd_endid_aprovado;
          this.end_id_contestado = response.data.quadros.qtd_endid_reprovado;
          this.valor_validado = response.data.quadros.valor_endid_aprovado;
          this.valor_contestado = response.data.quadros.valor_endid_reprovado;
          this.valor_total = response.data.quadros.valor_total;
          this.valor_aprovado_mes = response.data.quadros.valor_aprovado_mes;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: "Chamar o T.I.",
            life: 3000,
          });
        });
    },
    mostrarPlanilha2(planilha, mostrar) {
      if (mostrar == 2) {
        this.mostrarAcao = false
      }

      this.planilha = planilha;
      this.listarRelatorio(planilha.id);
      this.id_status = planilha.id_status
      this.id_planilha = planilha.id;
      this.mostrarPlanilhaModal = true;
    },
    mostrarPlanilha(planilha, mostrar) {
      if (mostrar == 2) {
        this.mostrarAcao = false
      }
      this.$router.push("/planilha/" + planilha.id);
      this.planilha = planilha;
      this.listarRelatorio(planilha.id);
      this.id_status = planilha.id_status
      this.id_planilha = planilha.id;
      this.mostrarPlanilhaModal = true;
    },
    montarHome() {
      let user = JSON.parse(localStorage.getItem("user"));

      if (user.id_tipo == 22913699) {
        this.mostrar_blocos = false;
        this.mostrar_blocos_fornecedor = true;
        this.mostrar_planilha = false;
      }
      if (user.id_tipo == 22959227) {
        this.mostrar_blocos = true;
        this.mostrar_blocos_fornecedor = false;
        this.mostrar_planilha = false;
        this.mostrar_financeiro = true
      }

    },
    listarInicial() {

      let user = JSON.parse(localStorage.getItem("user"));

      if (user.id_tipo == 22959227) {
        let dados = {
          id_fornecedor: this.id_fornecedor,
          ano: this.ano,
          mes: this.mes
        }
        this.loading = true;
        this.axios
          .post("home/financeiro", dados)
          .then((response) => {
            console.log(response)
            this.planilhas_importadas = response.data.dados.planilhas_importadas
            this.planilhas_validar = response.data.dados.planilhas_validar
            this.planilhas_validadas = response.data.dados.planilhas_validadas
            this.planilhas_reprovadas = response.data.dados.planilhas_reprovadas
            this.planilhas_pagamento_aprovado = response.data.dados.planilhas_pagamento_aprovado
            this.planilhas_pagamento_rejeitado = response.data.dados.planilhas_pagamento_rejeitado

            this.total_end_id = response.data.dados.qtd_endid_enviado;
            this.end_id_validado = response.data.dados.qtd_endid_aprovado;
            this.end_id_contestado = response.data.dados.qtd_endid_reprovado;
            this.valor_validado = response.data.dados.valor_endid_aprovado;
            this.valor_contestado = response.data.dados.valor_endid_reprovado;
            this.valor_total = response.data.dados.valor_total;
            this.valor_aprovado_mes = response.data.dados.valor_aprovado_mes;


            this.planilhas = response.data.dados.planilhas
            this.loading = false;
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
            this.loading = false;
          });
      }
      else {
        let dados = {
          id_fornecedor: this.id_fornecedor,
          ano: this.ano,
          mes: this.mes
        }
        this.loading = true;
        this.axios
          .post("home/inicial", dados)
          .then((response) => {
            console.log(response)
            this.planilhas_importadas = response.data.dados.planilhas_importadas
            this.planilhas_validar = response.data.dados.planilhas_validar
            this.planilhas_validadas = response.data.dados.planilhas_validadas
            this.planilhas_reprovadas = response.data.dados.planilhas_reprovadas
            this.planilhas_pagamento_aprovado = response.data.dados.planilhas_pagamento_aprovado
            this.planilhas_pagamento_rejeitado = response.data.dados.planilhas_pagamento_rejeitado

            this.planilhas = response.data.dados.planilhas
            this.loading = false;
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Ops!",
              detail: "Chamar o T.I.",
              life: 3000,
            });
            this.loading = false;
          });
      }

    },
    formatCurrency(value) {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    isDarkTheme() {
      return this.$appState.darkTheme === true;
    },
    applyLightTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      };
    },
    applyDarkTheme() {
      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: "#ebedef",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#ebedef",
            },
            grid: {
              color: "rgba(160, 167, 181, .3)",
            },
          },
          y: {
            ticks: {
              color: "#ebedef",
            },
            grid: {
              color: "rgba(160, 167, 181, .3)",
            },
          },
        },
      };
    },
    listarFornecedor() {
      this.axios
        .post("/fornecedores/listar")
        .then((response) => {
          console.log(response);
          let options = [];
          let desc_fornecedores = {};
          response.data.dados.map((el) => {
            let option = { value: el["id"], label: el["razao_social"] };
            desc_fornecedores[el["id"]] = el["razao_social"];
            options.push(option);
          });
          this.fornecedores = options;
          this.desc_fornecedores = desc_fornecedores;
          console.log(desc_fornecedores)
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.p-datatable-thead,
.p-datatable-tbody {

  white-space: nowrap !important;

}
</style>